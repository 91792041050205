<template>
  <!-- modal -->
  <b-modal
    id="add-location-modal"
    ref="add-location-modal"
    title="Registro de Visita"
    ok-title="Registrar"
    cancel-title="Cancelar"
    cancel-variant="outline-secondary"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-form-group
        :state="commentState"
        label="Nombre de Ubicacion"
        label-for="comment-input"
        invalid-feedback="Nombre de ubicacion requerida"
      >
        <b-form-textarea
          id="comment-input"
          v-model="comment"
          :state="commentState"
          required
        />
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BModal, VBModal, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import ticketsServices from '@core/services/tickets/ticketsService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormGroup,
    BFormTextarea,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    refreshHandler: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    comment: '',
    commentState: null,
    idTicket: 0,
  }),
  created() {
    this.$bus.$on('setParamsAddCommentModal', param => {
      const { id } = param
      this.idTicket = id
    })
  },
  methods: {
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition, this.showError)
      } else {
        alert('La geolocalización no es compatible con este navegador.')
      }
    },
    showPosition(position) {
      const { latitude, longitude } = position.coords
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Ubicacion obtenida satisfactoriamente',
          icon: 'MessageSquareIcon',
          variant: 'success',
        },
      })
      console.log(latitude, longitude)
      this.$nextTick(() => {
        this.$refs['add-location-modal'].toggle('#toggle-btn')
      })
    },
    showError(error) {
      let errorMessage = 'Algo salio mal'
      switch (error.code) {
        case error.PERMISSION_DENIED:
          errorMessage = 'El usuario denegó la solicitud de Geolocalización. Por favor, otorga permiso para acceder a la ubicación.'
          break
        case error.POSITION_UNAVAILABLE:
          errorMessage = 'La información de ubicación no está disponible.'
          break
        case error.TIMEOUT:
          errorMessage = 'La solicitud para obtener la ubicación del usuario ha expirado.'
          break
        case error.UNKNOWN_ERROR:
          errorMessage = 'Ha ocurrido un error desconocido.'
          break
        default:
          break
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: errorMessage,
          icon: 'MessageSquareIcon',
          variant: 'error',
        },
      })
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.commentState = valid
      return valid
    },
    resetModal() {
      this.comment = ''
      this.commentState = null
      this.idTicket = 0
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      //   const comm = {
      //     text: this.comment,
      //     id: this.idTicket,
      //   }
      this.getLocation()
      // Push the name to submitted names
    //   ticketsServices.commentTicket(comm).then(() => {
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'Success',
    //         text: 'La ubicacion ha sido registrada',
    //         icon: 'InboxIcon',
    //         variant: 'success',
    //       },
    //     })
    //     this.refreshHandler()
    //     // Hide the modal manually
    //     this.$nextTick(() => {
    //       this.$refs['add-comment-modal'].toggle('#toggle-btn')
    //     })
    //   }).catch(error => {
    //     this.toastErrorHandler(error)
    //   })
    },
  },
}
</script>
