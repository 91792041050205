export default [
  {
    title: 'Inicio',
    route: 'inicio',
    icon: 'HomeIcon',
  },
  {
    title: 'Reportes',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Estilos por catalogo',
        route: 'estilos-catalogo',
      },
      {
        title: 'Pendiente...',
        route: { name: 'apps-invoice-preview', params: { id: 4987 } },
      },
      {
        title: 'Pendiente Add...',
        route: { name: 'apps-invoice-add' },
      },
    ],
  },
  {
    title: 'Configuracion',
    icon: 'ToolIcon',
    children: [
      {
        title: 'Control de Acceso',
        route: 'config-permisos',
      },
      {
        title: 'Catalogos',
        route: 'config-catalogs',
      },
    ],
  },
]
